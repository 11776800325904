<template>
<div class="head-container">
  <el-select
      v-model="type"
      @change="toQuery"
      clearable
      placeholder="积分类型"
      class="filter-item"
      style="width: 130px"
  >
    <el-option
        v-for="item in typeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
    />
  </el-select>
  <el-select
      v-model="doType"
      @change="toQuery"
      clearable
      placeholder="操作类型"
      class="filter-item"
      style="width: 130px"
  >
    <el-option
        v-for="item in doTypeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
    />
  </el-select>
  <el-select
      v-model="status"
      @change="toQuery"
      clearable
      placeholder="状态"
      class="filter-item"
      style="width: 130px"
  >
    <el-option
        v-for="item in statusOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
    />
  </el-select>


  <el-input
      v-model="ruleName"
      clearable
      placeholder="规则名称"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
  />
  <el-button
      class="filter-item"
      size="mini"
      type="primary"
      icon="el-icon-search"
      @click="toQuery"
  >搜索</el-button>
  <el-button
      class="filter-item"
      size="mini"
      type="primary"
      icon="el-icon-plus"
      @click="toAdd"
  >添加</el-button>
</div>
</template>

<script>
export default {
  name: 'eheader',
  data() {
    return {
      type: '',
      typeOptions: [
        { label: '交易积分', value: 1 },
        { label: '行为积分', value: 2 },
      ],
      doType: '',
      doTypeOptions: [
        { label: '首次入金', value: 1 },
        { label: '单次入金', value: 2 },
      ],
      status: '',
      statusOptions:[
        { label: '禁用', value: 0 },
        { label: '启用', value: 1 },
      ],
      ruleName: '',
    }
  },
  methods: {
    toQuery() {
      this.$emit('toQuery');
    },
    clear(){

    },
    toAdd(){
      this.$emit('add')
    }
  }
}
</script>

<style scoped>

</style>
