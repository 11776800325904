<template>
  <div class="app-container summary-page trading-record">
    <eHeader
      ref="eheader"
      @toQuery="toQueryHandle"
      @add="addHandle"
    ></eHeader>
    <el-table
        v-loading="loading"
        :data="data"
        stripe
        size="small"
        style="width: 100%;"
    >
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="login" label="积分类型">
        <template slot-scope="scope">
          <span>{{getRewardType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="count" label="操作类型">
        <template slot-scope="scope">
          <span>{{getActionType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ruleName" label="规则名称" />
      <el-table-column prop="categoryNames" label="交易种类">
        <template slot-scope="scope">
          <span>{{scope.row.categoryNames || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="cmd" label="MT分组">
        <template slot-scope="scope">
          <span>{{scope.row.groupNames || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="openPrice" label="达标金额">
        <template slot-scope="scope">
          <span>{{scope.row.successAmount || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="openPrice" label="积分计算">
        <template slot-scope="scope">
          <span>{{getSCroseComputed(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" />
      <el-table-column prop="openPrice" label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column
          width="150px"
          prop="openPrice"
          label="操作">
        <template slot-scope="scope">
          <el-button
              v-if="checkPermission(['ADMIN','TCustRewardRule_ALL','TCustRewardRule_EDIT'])"
              size="mini"
              @click="editHandle(scope.row)"
              type="success"
          >编辑</el-button>
          <el-popover
              v-if="checkPermission(['ADMIN','TCustRewardRule_ALL','TCustRewardRule_DELETE'])"
              :ref="scope.row.id"
              placement="top"
              width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="deleteHandle(scope.row.id)">确定</el-button>
            </div>
            <el-button class="my-button" slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <add-or-edit-rule
        ref="dialog"
        :edit-data="editData"
        :dialog-show="editShow"
        @close="editShow = false"
        :is-edit="isEdit"
        @success="success"
    ></add-or-edit-rule>
  </div>
</template>

<script>
import { deleteRule } from '@/api/intergratingManage'
import addOrEditRule from '@/components/intergratingManage/intergralRule/addOrEditRule'
import checkPermission from '@/utils/permission'
import initDataComm from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/intergratingManage/intergralRule/eheader'
export default {
  name:'intergralRule',
  components: {
    eHeader,
    addOrEditRule,
  },
  mixins: [initDataComm],
  data() {
    return {
      loading: false,
      editShow: false,
      isEdit: false,
      editData: {},
      delLoading: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      let header = this.$refs.eheader;
      this.url = 'crm/rewardRule';
      this.params = {
        page: this.page,
        size: this.size,
        sort: 'id,desc',
      }
      if(header.type){
        this.params.rewardType = header.type;
      }
      if(header.doType){
        this.params.actionType = header.doType;
      }
      if(header.status !== ''){
        this.params.status = header.status;
      }
      if(header.ruleName){
        this.params.ruleName = header.ruleName;
      }
      return true
    },
    getRewardType(row){
      if(row.rewardType == 1){
        return '交易积分';
      }else if(row.rewardType == 2){
        return '行为积分';
      }
    },
    getActionType(row){
      if(row.actionType == 1){
        return '首次入金';
      }else if(row.actionType == 2){
        return '单次入金';
      }else {
        return '-';
      }
    },
    getSCroseComputed(row){
      switch (row.calcMode){
        case 1:
          return `${row.calcCoefficient}积分/手`;
        case 2:
          return `${row.calcCoefficient}积分/次`;
        case 3:
          return `比例: ${row.calcCoefficient}%`;
      }
    },
    getStatus(row){
      switch (row.status){
        case 0:
          return '禁用';
        case 1:
          return '启用';
      }
    },
    deleteHandle(id){
      this.delLoading = true;
      deleteRule({
        id: id,
      }).then((res) => {
        this.$refs[id].doClose();
        this.delLoading = false;
        this.init();
        this.$notify({
          message: '删除成功',
          duration: 2000,
        })
      }).catch(err => {
        this.delLoading = false;
        this.$refs[id].doClose();
      })
    },
    editHandle(row){
      this.isEdit = true;
      this.editShow = true;
      this.editData = row;
    },
    addHandle(){
      this.isEdit = false;
      this.editShow = true;
      this.$refs.dialog.clear();
    },
    toQueryHandle(){
      this.init();
    },
    queryHandle(){
      this.page = 0;
      this.init();
    },
    success(){
      this.editShow = false;
      this.page = 0;
      this.init();
    },
  }
}
</script>

<style lang="less">
  .trading-record {
    .my-button {
      margin-left: 5px;
    }
  }
</style>
