<template>
<el-dialog
  :visible.sync="dialogShow"
  :title="title"
  :before-close="handleClose"
  append-to-body
  width="600px"
  class="add-or-edit-rule"
>
  <el-form
    :model="form"
    ref="form"
    :rules="rules"
    label-position="right"
    label-width="110px"
  >
    <el-form-item
        prop="type"
        label="积分类型"
    >
      <el-radio-group
          v-model="form.type"
          :disabled="isEdit"
          @change="radioTypeChange"
      >
        <el-radio
            :label="1"
        >交易积分</el-radio>
        <el-radio
            :label="2"
        >行为积分</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      label="规则名称"
      prop="name"
    >
      <el-input
        v-model="form.name"
        placeholder="请输入"
        maxlength="20"
        show-word-limit
      ></el-input>
    </el-form-item>
    <el-form-item
        v-if="!isBehaviorIntegral"
      label="MT分组"
      prop="group"
    >
      <el-select
          placeholder="请选择"
          v-model="form.group"
          multiple
      >
<!--        form.group 和form.dealCategory有值匹配不上bug todo-->
        <el-option
            v-for="(item,index) in groupList"
            :key="index"
            :label="item.groupName"
            :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item
        v-if="!isBehaviorIntegral"
        label="交易种类"
        prop="dealCategory"
        key="dealCategory"
    >
      <el-select
          placeholder="请选择"
          v-model="form.dealCategory"
          multiple
      >
        <el-option
            v-for="(item,index) in cateList"
            :key="index"
            :label="item.catName"
            :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item
        v-if="isBehaviorIntegral"
        label="操作类型"
        prop="doType"
        key="doType"
    >
      <el-select
          placeholder="请选择"
          v-model="form.doType"
      >
        <el-option
            v-for="(item,index) in doTypeList"
            :key="index"
            :label="item.label"
            :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item
        v-if="isBehaviorIntegral"
        label="排除支付渠道"
        prop="excludeChannel"
        key="excludeChannel"
    >
      <el-select
          placeholder="请选择"
          v-model="form.excludeChannel"
          multiple
          @change="$forceUpdate()"
      >
        <el-option
            v-for="(item,index) in excludeChannelList"
            :key="index"
            :label="item.channelName"
            :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item
        v-if="isBehaviorIntegral"
        label="达标金额"
        prop="markAmount"
    >
      <el-input
          placeholder="请输入"
          v-model="form.markAmount"
          type="number"
      >
        <span slot="suffix" class="">USD</span>
      </el-input>
    </el-form-item>
    <el-form-item
      label="积分计算"
      prop="integratingComputed"
    >
      <el-input
        v-model="form.integratingComputed"
        placeholder="请输入"
      >
        <el-select
            slot="prepend"
            placeholder="请选择"
            v-model="form.computedType"
            style="width: 100px"
            :disabled="computedTypeDisabled"
        >
          <el-option
              v-for="(item,index) in totalComputedRatingList"
              :key="index"
              :label="item.label"
              :value="item.id"
          ></el-option>
        </el-select>
        <div
            v-if="computedSuffixShow"
            slot="suffix"
            class="computed-suffix"
        >%</div>
      </el-input>
    </el-form-item>
    <el-form-item
      label="状态"
      prop="status"
    >
      <el-radio-group
          v-model="form.status"
      >
        <el-radio
            :label="1"
        >启用</el-radio>
        <el-radio
            :label="0"
        >禁用</el-radio>
      </el-radio-group>
    </el-form-item>
  </el-form>
  <div class="dialog-footer" slot="footer">
    <el-button
        :loading="btnLoading"
        type="primary"
        @click="sureHandle"
    >确定</el-button>
  </div>
</el-dialog>
</template>

<script>
import { addRule, editRule } from '@/api/intergratingManage';
import { mapGetters } from 'vuex'
import { initData } from '@/api/data'
export default {
  name: 'addOrEditRule',
  props: {
    editData: {
      type: Object,
      default(){
        return {

        }
      }
    },
    isEdit:{
      type: Boolean,
      default: false,
    },
    dialogShow:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      form: {
        name: '',
        type: 1,
        // 交易积分
        group: [],
        dealCategory: [],
        //行为积分
        doType: 1,
        markAmount: '',
        //
        computedType: 1,
        integratingComputed: '',
        status: 1,
      },
      rules:{
        type: [
          { required: true, message: '请选择积分类型', trigger: 'change' },
        ],
        name: [
          { required: true, message: '请输入规则名称', trigger: 'blur' },
        ],
        group: [
          { required: true, message: '请选择MT分组', trigger: 'change' },
        ],
        dealCategory: [
          { required: true, message: '请选择交易种类', trigger: 'change' },
        ],
        //行为积分
        doType: [
          { required: true, message: '请选择操作类型', trigger: 'change' },
        ],
        excludeChannel: [
          // { required: true, message: '请选择操排除的支付渠道', trigger: 'change' },
        ],
        markAmount: [
          { required: true, message: '请输入达标金额', trigger: 'blur' },
        ],
        computedType: [
          { required: true, message: '请选择积分计算类型', trigger: 'change' },
        ],
        integratingComputed: [
          { required: true, message: '请输入积分计算', trigger: 'blur' },
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'change' },
        ],
      },
      groupList: [],
      cateList: [],
      dealCategoryList: [
        { label: '测试', id: '1' },
      ],
      doTypeList:[
        { label: '首次入金', id: 1 },
        { label: '单次入金', id: 2 },
      ],
      excludeChannelList:[],
      computedTypeList: [
        { label: '每手', id: 1 },
      ],
      behaviorComputedTypeList: [
        { label: '固定', id: 2 },
        { label: '比例', id: 3 },
      ],
      btnLoading: false,
    }
  },
  created(){
    this.getGroupList();
    this.getCateList();
    this.getEnterChannelList();
  },
  watch:{
    dialogShow(val){
      this.$refs.form  && this.$refs.form.clearValidate();
      this.$refs.form && this.$refs.form.clearValidate(['group','dealCategory','doType','excludeChannel','markAmount']);
      if(this.isEdit){
        this.assign(this.editData);
      }
    },
    'form.type'(val){
      this.$refs.form && this.$refs.form.clearValidate(['group','dealCategory','doType','excludeChannel','markAmount']);
    },
    editData:{
      handler(newValue,oldValue){

        // 编辑的测试和联调 todo
      },
      deep: true,
    },
    isEdit(newValue,oldValue){
      if(!newValue){

      }
    },
  },
  computed: {
    title() {
      return this.isEdit ? '编辑': '添加'
    },
    isBehaviorIntegral(){
      return this.form.type == 2;
    },
    totalComputedRatingList(){
      if(this.form.type == 1){
        return this.computedTypeList;
      }else {
        return this.behaviorComputedTypeList;
      }
    },
    computedSuffixShow(){
      if((this.form.type == 2) && this.form.computedType == 3){
        return true;
      }else {
        return false;
      }
    },
    computedTypeDisabled(){
      return this.form.type == 1;
    }
  },
  methods: {
    addRule,
    editRule,
    assign(newValue){
      if(newValue.id){
        this.form.id = newValue.id;
      }
      this.form.type = newValue.rewardType;
      this.form.name = newValue.ruleName;
      if(newValue.groupIds){
        let groupArr = newValue.groupIds.split(',');
        for (let i = 0; i < groupArr.length; i++) {
          groupArr[i] = +groupArr[i];
        }
        this.form.group = groupArr;
      }
      if(newValue.categoryIds){
        let cateArr = newValue.categoryIds.split(',');
        for (let i = 0; i <cateArr.length ; i++) {
          cateArr[i] = +cateArr[i];
        }
        this.form.dealCategory = cateArr;
      }

      this.form.doType = newValue.actionType;
      if(newValue.excludePayTypes){
        let arrExclude = newValue.excludePayTypes.split(',');
        for (let i = 0; i < arrExclude.length; i++) {
          arrExclude[i] = +arrExclude[i];
        }
        this.form.excludeChannel = arrExclude;
      }
      this.form.markAmount = newValue.successAmount;

      this.form.computedType = newValue.calcMode;
      this.form.integratingComputed = newValue.calcCoefficient;

      this.form.status = newValue.status;
    },
    getGroupList(){
      const url='/crm/tMt4Group'
      let parmas = {
        isMock:2
      }
      initData(url, parmas).then(res => {
        this.groupList = res.content;
      }).catch(err => {

      })
    },
    getCateList(){
      const url='crm/tMt4TradeCategory'
      initData(url, '').then(res => {
        this.cateList = res.content;
      }).catch(err => {
      })
    },
    getEnterChannelList(){
       let url = 'crm/queryPayChannels';
       let params = {
         page: 0,
         size: 500,
         oprType: 1,
       }
       initData(url,params).then((res) => {
         this.excludeChannelList = res.content;
       });
    },
    sureHandle() {
      this.$refs.form.validate((valid) => {
        if(valid){
          let params = {
            rewardType: this.form.type,
            ruleName: this.form.name,
            calcCoefficient: this.form.integratingComputed,
            status: this.form.status,
          }

          let prop = '';
          if(!this.isEdit){
            // 添加
            prop = 'addRule';
          }else {
            prop = 'editRule'
            // 编辑
            params.id = this.form.id;
          }

          // 交易积分
          if(this.form.type == 1){
            params.groupIds = this.form.group.join(',');
            params.categoryIds = this.form.dealCategory.join(',');
            params.calcMode = 1;
          }else if(this.form.type == 2){
            params.actionType = this.form.doType;
            params.excludePayTypes = this.form.excludeChannel.join(',');
            params.successAmount = this.form.markAmount;
            params.calcMode = this.form.computedType;
          }
          this[prop](params).then((res) => {
            console.log(res);
            this.$notify({
              message: '操作成功',
              duration: 2000,
            });
            this.$emit('success');
          })
        }else {
          console.log('no-pass');
        }
      })
    },
    handleClose(){
      this.$emit('close');
    },
    radioTypeChange(e){
      if(e !== 1){
        this.form.computedType = 2;
        this.$refs.form.clearValidate(['excludeChannel']);
      }else {
        this.form.computedType = 1;
        this.$refs.form.clearValidate(['group']);
      }
    },
    clear(){
      this.form = {
        type: 1,
        // 交易积分
        group: [],
        dealCategory: [],
        //行为积分
        doType: 1,
        markAmount: '',
        //
        computedType: 1,
        integratingComputed: '',
        status: 1,
      };
      this.$refs.form && this.$refs.form.clearValidate(['group','dealCategory','doType','excludeChannel','markAmount']);
    },
  }
}
</script>

<style lang="less" scoped>
.add-or-edit-rule {
  .el-select {
    width: 100%;
  }
  /deep/ .el-dialog__body {
    padding-right: 30px;
  }
  .computed-suffix {
    line-height: 40px;
  }
}
</style>
